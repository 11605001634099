import React from "react";
import star1 from "../../img/Component1.svg";
import star2 from "../../img/Component2.svg";
import phone from "../../img/phone.svg";
import map from "../../img/maping.svg";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
const Tables = ({ handleExchangeClick, bankData, storedExchanges }) => {
  const navigate = useNavigate();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="blocks-block">
      <div className="slider-container">
        <Slider {...settings}>
          {bankData?.map((el, id) => (
            <div key={id}>
              <div className="grid gap-grid">
                <div className="save-one">
                  <div className="carob lanes">
                    <div className="logos-images">
                      <img className="logos" src={el.logo} alt="" />
                    </div>
                    <div className="gray_texts_flex">
                      <div className="gray_texts">
                        <p
                          className="title"
                          onClick={() =>
                            localStorage.getItem("token")
                              ? navigate(`/exchange-detail/${el.slug}`)
                              : navigate("/login")
                          }
                        >
                          {el.exchanger}
                        </p>
                        <div className="gray_texts_flex_one">
                          <img className="icon" src={phone} alt="" />
                          <p className="gray">{el.phone}</p>
                        </div>
                      </div>
                      <div className="gray_texts_flex_one">
                        <img className="icon" src={map} alt="" />
                        <p className="gray">{el.address}</p>
                      </div>
                    </div>
                  </div>
                  <div className="grid_flex-img">
                    <div className="time_el">
                      <p className="time">{el.deadline}</p>
                    </div>
                    <img
                      onClick={() => handleExchangeClick(el)}
                      className="star"
                      src={
                        storedExchanges.some(
                          (exchange) => exchange.id === el.id
                        )
                          ? star2
                          : star1
                      }
                      alt="starred"
                    />
                  </div>
                </div>
                <div className="bay-sell-d-flex">
                  <div className="grid_flex grid-flex-all "></div>
                  <div className="grid_flex buy-gap-div" key={el.id}>
                    <div>
                      <p className="buys chet" style={{ magin: "0" }}>
                        покупка
                      </p>
                    </div>
                    <div>
                      <p className="chet">
                        <p className="buys chet" style={{ magin: "0" }}>
                          продажа
                        </p>
                      </p>
                    </div>
                  </div>
                </div>
                {el.crypto.map((el) => (
                  <div className="grid_flex grid-flex-twos">
                    <div className="grid_flex grid-flex-all ">
                      {el.buy !== null && el.sell !== null ? (
                        <img src={el?.img} alt="" />
                      ) : (
                        ""
                      )}

                      <p>{el?.code_name}</p>
                    </div>
                    <div
                      className={
                        el.buy !== null && el.sell !== null
                          ? "grid_flex buy-gap"
                          : ""
                      }
                      key={el.id}
                    >
                      <div>
                        <p className="chet">
                          {el && el.buy !== null ? `$${el.buy}` : ""}
                        </p>
                      </div>
                      <div>
                        <p className="chet">
                          {el && el.sell !== null ? `$${el.sell}` : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Tables;
