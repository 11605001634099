import React from "react";
import { NavLink } from "react-router-dom";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";

const AverageCourse = ({ data }) => {
  console.log(data);
  return (
    <div className="nav_box">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" className="col-none"></th>
            <th scope="col">
              <div className="d-flex flex-column">
                <div className="row ">
                  <p className="title col-12">Средний курс</p>
                  <p className="title-1 col-12">за последние 2 часа</p>
                </div>
                <div className="row mt-4">
                  <p className="col-6 name">покупка</p>
                  <p className="col-6 name">продажа</p>
                </div>
              </div>
            </th>
            <th scope="col">
              <div className="d-flex flex-column">
                <div className="row">
                  <p className="title col-12">Лучший курс</p>
                  <p className="title-1 col-12">по участникам</p>
                </div>
                <div className="row mt-4  ">
                  <p className="col-6 name">покупка</p>
                  <p className="col-6 name">продажа</p>
                </div>
              </div>
            </th>
            <th scope="col">
              <div className="d-flex flex-column justify-content-end align-items-end">
                <div className=" d-flex flex-column justify-content-end align-items-end">
                  <p className="title">Курс Binance</p>
                  <p className="title-1 d-flex flex-column justify-content-end align-items-end">
                    все курсы
                  </p>
                </div>
                <div className="mt-4">
                  <p className="name">покупка</p>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data?.slice(0, 6).map((el, index) => (
              <tr>
                <th scope="row">
                  <span className="first d-flex align-items-center gap-2">
                    <div className="images-img">
                      <img src={el.img} alt="" />
                    </div>
                    <p className="crypto-name">{el.code_name}</p>
                  </span>
                </th>
                <td className={index === 0 ? "" : "bottom-line"}>
                  <NavLink to={`/page-coin/${el.slug}`}>
                    <div className="row">
                      <p className="number  col-6">
                        $
                        {typeof el.average.buy === "string" ||
                        typeof el.average.buy === "number"
                          ? el.average.buy.toString().slice(0, 8)
                          : ""}
                      </p>
                      <p className="number  col-6">
                        $
                        {typeof el.average.sell === "string" ||
                        typeof el.average.sell === "number"
                          ? el.average.sell.toString().slice(0, 8)
                          : ""}
                      </p>
                    </div>
                  </NavLink>
                </td>
                <td className={index === 0 ? "" : "bottom-line"}>
                  <NavLink to={`/page-coin/${el.slug}`}>
                    <div className="row">
                      <p className="number blue col-6">
                        $
                        {typeof el.best_price.buy === "string" ||
                        typeof el.best_price.buy === "number"
                          ? el.best_price.buy.toString().slice(0, 8)
                          : ""}
                      </p>
                      <p className="number blue col-6">
                        $
                        {typeof el.best_price.sell === "string" ||
                        typeof el.best_price.sell === "number"
                          ? el.best_price.sell.toString().slice(0, 8)
                          : ""}
                      </p>
                    </div>
                  </NavLink>
                </td>
                <td className={index === 0 ? "" : "bottom-line"}>
                  <span className="last_s none-bay d-flex align-items-end justify-content-end gap-1">
                    <p className="number">${el.binance.sell}</p>
                    {el.binance.difference >= 0 ? (
                      <TiArrowSortedUp color="green" size={26} />
                    ) : (
                      <TiArrowSortedDown color="red" size={26} />
                    )}
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default AverageCourse;
