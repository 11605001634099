import React from 'react'
import "./NotFoundPage.css"

const NotFoundPage = () => {
    return (
        <div className='not_found_page'>
            <div className="container">
                <h1>404 Страница не найдена</h1>
            </div>
        </div>
    )
}

export default NotFoundPage