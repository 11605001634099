import React, { useEffect, useState } from "react";
import "./Table.css";
import logo1 from "../../img/logo1.svg";
import logo2 from "../../img/logo2.svg";
import logo3 from "../../img/logo3.svg";
import star1 from "../../img/Component1.svg";
import star2 from "../../img/Component2.svg";
import phone from "../../img/phone.svg";
import map from "../../img/maping.svg";
import arrow from "../../img/icon_arrow.svg";
import { api } from "../../Api";
import { Alert } from "../UI/alert/alert";
import { NavLink, useNavigate } from "react-router-dom";
import Tables from "./Tables";

const Table = ({ title }) => {
  const navigate = useNavigate();
  const [bankData, setBankData] = useState([]);
  const [storedExchanges, setStoredExchanges] = useState([]);

  useEffect(() => {
    api.get("/exchanger/currency-list/").then((response) => {
      setBankData(response.data.exchanger);
      const starredExchangers =
        JSON.parse(localStorage.getItem("active_exchanger")) || [];
      setStoredExchanges(starredExchangers);
    });
  }, []);

  const isTokenAvailable = () => {
    const token = localStorage.getItem("token");
    return token !== null && token !== undefined;
  };

  const starExchanger = (el) => {
    if (isTokenAvailable()) {
      api
        .post(
          `/star/exchanger/${el.id}/`,
          {},
          {
            headers: {
              Authorization: `Token ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.response === true) {
            Alert("success", response.data.message);
            const updatedExchangers = [...storedExchanges, el.id];
            setStoredExchanges(updatedExchangers);
            localStorage.setItem(
              "active_exchanger",
              JSON.stringify(updatedExchangers)
            );
          } else {
            Alert("error", response.data.message);
          }
        })
        .catch((error) => {
          console.error(
            "Error starring exchanger:",
            error.response ? error.response.data : error
          );
        });
    } else {
      navigate("/login");
    }
  };

  const startDelte = (id) => {
    api
      .delete(`/star/exchanger/${id}/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.data.response === true) {
          Alert("success", response.data.message);
          const updatedExchangers = storedExchanges.filter(
            (exchangerId) => exchangerId !== id
          );
          setStoredExchanges(updatedExchangers);
          localStorage.setItem(
            "active_exchanger",
            JSON.stringify(updatedExchangers)
          );
        } else {
          Alert("error", response.data.message);
        }
      })
      .catch((error) => {
        console.error(
          "Error starring exchanger:",
          error.response ? error.response.data : error
        );
      });
  };

  const uniqueCodes = Array.from(
    new Set(
      bankData?.flatMap((el) =>
        el?.crypto
          .map((cryptoEl) => cryptoEl?.img && cryptoEl?.code_name)
          .filter((codeName) => codeName)
      )
    )
  );

  return (
    <div style={title && { margin: 0 }} className="table">
      <div className="container">
        <div>
          {title ? (
            ""
          ) : (
            <div>
              <h2 className="title_h1">
                Курсы криптовалюты на сегодня в Бишкеке
              </h2>
              <p className="text_p">
                Курсы криптовалюты обновляются {" "}
                <a href="/" className="link">
                  обменными пунктами
                </a>
                . коммерческие банки Кыргызстана обновляют курс
                криптовалюты каждые 5 минут
              </p>
            </div>
          )}
          <div className="max-media">
            <div className="head_grid">
              <div className="width_col">
                <p className="gray"># Название</p>
              </div>
              {uniqueCodes.map((code, idx) => {
                const data = bankData.flatMap((el) =>
                  el.crypto.filter((cryptoEl) => cryptoEl.code_name === code)
                );
                return data.length > 0 ? (
                  <div className="box" key={idx}>
                    <div className="flex">
                      <div>
                        <img src={data[0].img} alt={data[0].code_name} />
                      </div>
                      <p className="title">{data[0].code_name}</p>
                    </div>
                    <div className="grid_col">
                      <p className="gray">покупка</p>
                      <p className="gray">продажа</p>
                    </div>
                  </div>
                ) : null;
              })}

              <div className="col">
                <img className="arrow" src={arrow} alt="" />
                <div>
                  <p className="gray">время</p>
                </div>
              </div>
            </div>
            <div className="blocks">
              {bankData?.map((el, id) => (
                <div key={id} className="grid">
                  <div className="save">
                    <img
                      onClick={() =>
                        storedExchanges.includes(el.id)
                          ? startDelte(el.id)
                          : starExchanger(el)
                      }
                      className="star"
                      src={storedExchanges.includes(el.id) ? star2 : star1}
                      alt="starred"
                    />
                    <div className="carob">
                      <div className="logos-images">
                        <img className="logos" src={el.logo} alt="" />
                      </div>
                      <div className="gray_texts_flex">
                        <div className="gray_texts">
                          <NavLink to={`/exchange-detail/${el.slug}`}>
                            <p className="title">{el.exchanger}</p>
                          </NavLink>
                          <div className="d-flex">
                            <img className="icon" src={phone} alt="" />
                            <p className="gray">{el.phone}</p>
                          </div>
                        </div>
                        <div
                          className="gray_texts_flex_one"
                          style={{ width: " 60%" }}
                        ></div>
                      </div>
                    </div>
                  </div>
                  {el.crypto.map((el) => (
                    <div className="grid_flex" key={el.id}>
                      <div>
                        <p className="chet">
                          {el && el.buy !== null ? `$${el.buy}` : "-----"}
                        </p>
                      </div>
                      <div>
                        <p className="chet">
                          {el && el.sell !== null ? `$${el.sell}` : "-----"}
                        </p>
                      </div>
                    </div>
                  ))}
                  <div className="time_el">
                    <p className="time">{el.deadline}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="min-media">
          <Tables
            bankData={bankData}
            handleExchangeClick={starExchanger}
            storedExchanges={storedExchanges}
          />
        </div>
      </div>
    </div>
  );
};

export default Table;
