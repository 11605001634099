import React, { useEffect, useState } from "react";
import "../../components/Table/Table.css";
import star2 from "../../img/Component2.svg";
import arrow from "../../img/icon_arrow.svg";
import { NavLink } from "react-router-dom";
import phone from "../../img/phone.svg";
import { api } from "../../Api";
import { Alert } from "../../components/UI/alert/alert";

const Favorites = () => {
  const [bankData, setBankData] = useState([]);
  const [storedExchanges, setStoredExchanges] = useState([]);

  const starFavorites = () => {
    api.get("/star-list/").then((response) => {
      setBankData(response.data.exchanger);
    });
  };

  useEffect(() => {
    starFavorites();
  }, []);

  const uniqueCodes = Array.from(
    new Set(
      bankData?.flatMap((el) =>
        el?.crypto
          .map((cryptoEl) => cryptoEl?.img && cryptoEl?.code_name)
          .filter((codeName) => codeName)
      )
    )
  );

  const startDelte = (id) => {
    api
      .delete(`/star/exchanger/${id}/`, {
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        if (response.data.response === true) {
          Alert("success", response.data.message);
          starFavorites();
          const updatedExchangers = storedExchanges.filter(
            (exchangerId) => exchangerId !== id
          );
          setStoredExchanges(updatedExchangers);
          localStorage.setItem(
            "active_exchanger",
            JSON.stringify(updatedExchangers)
          );
        }
        if (response.data.response === false) {
          Alert("error", response.data.message);
        }
      })
      .catch((error) => {
        console.error(
          "Error starring exchanger:",
          error.response ? error.response.data : error
        );
      });
  };

  useEffect(() => {
    document.title = "Избранное";
  }, []);

  return (
    <div className="table favorites " style={{ margin: "30px 0 50px 0" }}>
      <div className="container favorites-none">
        <h1 className="title_h1 mt-4">Избранное</h1>
        <div className="max-media">
          <div className="head_grid">
            <div className="width_col">
              <p className="gray"># Название</p>
            </div>
            {uniqueCodes.map((code, idx) => {
              const data = bankData.flatMap((el) =>
                el.crypto.filter((cryptoEl) => cryptoEl.code_name === code)
              );
              return data.length > 0 ? (
                <div className="box" key={idx}>
                  <div className="flex">
                    <div>
                      <img src={data[0].img} alt={data[0].code_name} />
                    </div>
                    <p className="title">{data[0].code_name}</p>
                  </div>
                  <div className="grid_col">
                    <p className="gray">покупка</p>
                    <p className="gray">продажа</p>
                  </div>
                </div>
              ) : null;
            })}

            <div className="col">
              <img className="arrow" src={arrow} alt="" />
              <div>
                <p className="gray">время</p>
              </div>
            </div>
          </div>
          <div className="blocks">
            {bankData?.map((el, id) => (
              <div key={id} className="grid">
                <div className="save">
                  <img
                    className="star"
                    onClick={() => startDelte(el.id)}
                    src={star2}
                    alt="starred"
                  />
                  <div className="carob">
                    <div className="logos-images">
                      <img className="logos" src={el.logo} alt="" />
                    </div>
                    <div className="gray_texts_flex">
                      <div className="gray_texts">
                        <NavLink to={`/exchange-detail/${el.slug}`}>
                          <p className="title">{el.exchanger}</p>
                        </NavLink>
                        <div className="d-flex">
                          <img className="icon" src={phone} alt="" />
                          <p className="gray">{el.phone}</p>
                        </div>
                      </div>
                      <div
                        className="gray_texts_flex_one"
                        style={{ width: " 60%" }}
                      ></div>
                    </div>
                  </div>
                </div>
                {el.crypto.map((el) => (
                  <div className="grid_flex" key={el.id}>
                    <div>
                      <p className="chet">
                        {el && el.buy !== null ? `$${el.buy}` : "-----"}
                      </p>
                    </div>
                    <div>
                      <p className="chet">
                        {el && el.sell !== null ? `$${el.sell}` : "-----"}
                      </p>
                    </div>
                  </div>
                ))}
                <div className="time_el">
                  <p className="time">{el.deadline}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Favorites;
